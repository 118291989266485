import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _51c91242 = () => interopDefault(import('../pages/addFriend.vue' /* webpackChunkName: "pages/addFriend" */))
const _26304f9c = () => interopDefault(import('../pages/article/index.vue' /* webpackChunkName: "pages/article/index" */))
const _11df491c = () => interopDefault(import('../pages/config/index.vue' /* webpackChunkName: "pages/config/index" */))
const _4ad616ba = () => interopDefault(import('../pages/confirm.vue' /* webpackChunkName: "pages/confirm" */))
const _3b2be011 = () => interopDefault(import('../pages/products.vue' /* webpackChunkName: "pages/products" */))
const _3430914a = () => interopDefault(import('../pages/shipping.vue' /* webpackChunkName: "pages/shipping" */))
const _1d00f934 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _7a74f466 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _79093fb2 = () => interopDefault(import('../pages/template.vue' /* webpackChunkName: "pages/template" */))
const _305e7e84 = () => interopDefault(import('../pages/config/delivery.vue' /* webpackChunkName: "pages/config/delivery" */))
const _a9236ec8 = () => interopDefault(import('../pages/config/guide.vue' /* webpackChunkName: "pages/config/guide" */))
const _78434434 = () => interopDefault(import('../pages/config/history.vue' /* webpackChunkName: "pages/config/history" */))
const _0beabf4b = () => interopDefault(import('../pages/config/mypage.vue' /* webpackChunkName: "pages/config/mypage" */))
const _2bd12227 = () => interopDefault(import('../pages/config/privacy-policy.vue' /* webpackChunkName: "pages/config/privacy-policy" */))
const _6a56e545 = () => interopDefault(import('../pages/config/regist-shipping.vue' /* webpackChunkName: "pages/config/regist-shipping" */))
const _452caed2 = () => interopDefault(import('../pages/config/specified-commercial.vue' /* webpackChunkName: "pages/config/specified-commercial" */))
const _64e707ec = () => interopDefault(import('../pages/config/template.vue' /* webpackChunkName: "pages/config/template" */))
const _6201e422 = () => interopDefault(import('../pages/config/test.vue' /* webpackChunkName: "pages/config/test" */))
const _ca1e1126 = () => interopDefault(import('../pages/article/_article_id.vue' /* webpackChunkName: "pages/article/_article_id" */))
const _f1df48bc = () => interopDefault(import('../pages/complete/_order_id.vue' /* webpackChunkName: "pages/complete/_order_id" */))
const _50683475 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/addFriend",
    component: _51c91242,
    name: "addFriend"
  }, {
    path: "/article",
    component: _26304f9c,
    name: "article"
  }, {
    path: "/config",
    component: _11df491c,
    name: "config"
  }, {
    path: "/confirm",
    component: _4ad616ba,
    name: "confirm"
  }, {
    path: "/products",
    component: _3b2be011,
    name: "products"
  }, {
    path: "/shipping",
    component: _3430914a,
    name: "shipping"
  }, {
    path: "/shop",
    component: _1d00f934,
    name: "shop"
  }, {
    path: "/success",
    component: _7a74f466,
    name: "success"
  }, {
    path: "/template",
    component: _79093fb2,
    name: "template"
  }, {
    path: "/config/delivery",
    component: _305e7e84,
    name: "config-delivery"
  }, {
    path: "/config/guide",
    component: _a9236ec8,
    name: "config-guide"
  }, {
    path: "/config/history",
    component: _78434434,
    name: "config-history"
  }, {
    path: "/config/mypage",
    component: _0beabf4b,
    name: "config-mypage"
  }, {
    path: "/config/privacy-policy",
    component: _2bd12227,
    name: "config-privacy-policy"
  }, {
    path: "/config/regist-shipping",
    component: _6a56e545,
    name: "config-regist-shipping"
  }, {
    path: "/config/specified-commercial",
    component: _452caed2,
    name: "config-specified-commercial"
  }, {
    path: "/config/template",
    component: _64e707ec,
    name: "config-template"
  }, {
    path: "/config/test",
    component: _6201e422,
    name: "config-test"
  }, {
    path: "/article/:article_id",
    component: _ca1e1126,
    name: "article-article_id"
  }, {
    path: "/complete/:order_id?",
    component: _f1df48bc,
    name: "complete-order_id"
  }, {
    path: "/",
    component: _50683475,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
